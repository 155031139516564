import React from 'react'
import PropTypes from 'prop-types'
import StyledMiniMap from './style'

const MiniMap = ({ children }) => {
  return <StyledMiniMap>{children}</StyledMiniMap>
}

MiniMap.propTypes = {
  children: PropTypes.any,
}

export { MiniMap }
