import styled from 'styled-components'
import { mq } from '../../styles/utilities/variables'

const StyledMiniMap = styled.div`
  height: 550px;
  margin-bottom: 14px;

  /* & .leaflet-control-container {
    display: none;
  } */

  @media ${mq.mobile} {
    height: auto;
    margin-bottom: 0;
  }
`

export default StyledMiniMap
