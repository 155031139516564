import React, { useEffect, useState } from 'react'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { capitalize, usePageData } from '../../helpers/utilities'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Text } from '../../components/Text'
import { Container } from '../../components/Container'
import { PageContent } from '../../components/PageContent'
import { Grid } from '../../components/Grid'
import { MapCanvas } from '../../components/MapCanvas'
import { MiniMap } from '../../components/MiniMap'
import { MapHeader } from '../../components/MapHeader'
import { PageGallery } from '../../components/PageGallery'
import { TextLink } from '../../components/TextLink'
import { Content } from '../../components/Content'
import { useTranslation } from '../../hooks/useTranslation'
import { SPACING } from '../../helpers/constants'
import { Button } from '../../components/Button'

export default ({ data, pageContext }) => {
  const getTranslation = useTranslation()
  const { dealer } = usePageData(data, pageContext)
  const { title, seo, acfAttributes } = dealer
  const [dealers, setDealers] = useState(null)
  const isShop = acfAttributes.dealerShopCenter
    ? getTranslation('shopText')
    : ''
  const isService = acfAttributes.dealerServiceCenter
    ? getTranslation('workshopText')
    : ''
  const isBoth =
    isShop && isService ? `${isShop} + ${isService}` : isShop || isService

  const contact = {
    shop: isBoth,
    phone: acfAttributes.dealerPhone,
    address1: acfAttributes.dealerAddress,
    address2: acfAttributes.dealerAddress2,
    zipCode: acfAttributes.dealerZip,
    city: capitalize(acfAttributes.dealerCity),
    email: acfAttributes.dealerEmail,
    more: acfAttributes.dealerInformation,
    text: acfAttributes.dealerText,
    media: acfAttributes.dealerAdditionalMedia,
    maps: false, // when we have this available
    latitude: acfAttributes.dealerLatitude,
    longitude: acfAttributes.dealerLongitude,
  }

  const getContactInfoText = () => {
    let txt = ''

    if (contact.shop) txt += contact.shop + '<br>'
    if (contact.phone) txt += contact.phone + '<br>'
    if (contact.address1) txt += contact.address1 + '<br>'
    if (contact.address2) txt += contact.address2 + '<br>'
    if (contact.zipCode) txt += contact.zipCode + ' '
    if (contact.city) txt += contact.city
    // if (contact.email) txt += contact.email // Dont show for now

    return txt
  }

  const baseUrl = process.env.GATSBY_GOOGLE_MINIMAP_URL
    ? process.env.GATSBY_GOOGLE_MINIMAP_URL
    : 'https://www.google.com/maps/search/?api=1&query='
  const mapUrl = `${baseUrl}${contact.latitude},${contact.longitude}`

  useEffect(() => {
    setDealers(dealer)
  }, [data])

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <MapHeader atList />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      {contact.media && (
        <PageGallery
          noMargin
          hideZoom
          images={undefined}
          mediaItems={contact.media}
        />
      )}
      <PageContent>
        <Container>
          <IndentWrapper col={3}>
            <Heading>{title}</Heading>
            <Divider />
            <Grid col={2}>
              <Content>
                <p dangerouslySetInnerHTML={{ __html: getContactInfoText() }} />
                <Button href={mapUrl} target={'_blank'} textColor='#fff'>
                  <span style={{ color: '#fff' }}>
                    {getTranslation('directionsText')}
                  </span>
                </Button>
                {contact.maps && (
                  <TextLink
                    underline
                    to={'/'}
                    text={`${getTranslation('directionsText')}, Google Maps`}
                  />
                )}
              </Content>
              <Content>
                <div dangerouslySetInnerHTML={{ __html: contact.more }} />
              </Content>
            </Grid>

            <Divider
              size={{
                desktop: SPACING.MEDIUM,
                tablet: SPACING.SMALL,
                mobile: SPACING.XX_SMALL,
              }}
            />

            <Content>
              <Text columns={2}>{contact.text ? contact.text : null}</Text>
            </Content>
          </IndentWrapper>
          <Divider />
          <MiniMap>
            <MapCanvas dealers={[dealer]} zoom={14} />
          </MiniMap>
        </Container>
      </PageContent>
    </Layout>
  )
}
